import Vue from "vue"
import VueRouter from "vue-router"
import HomePage from "@/views/HomePage.vue"

Vue.use(VueRouter)

const routes = [
    {
        path: "/gallery",
        name: "gallery",
        component: () => import("@/views/GalleryPage.vue"),
    },
    {
        path: "/services",
        name: "services",
        component: () => import("@/views/ServicesPage.vue"),
    },
    {
        path: "/aboutus",
        name: "aboutus",
        component: () => import("@/views/AboutUsPage.vue"),
    },
    {
        path: "/contactus",
        name: "contactus",
        component: () => import("@/views/ContactUsPage.vue"),
    },
    {
        path: "/home",
        name: "home",
        component: HomePage,
    },
    {
        path: "/",
        name: "root",
        component: HomePage,
    }
];

const router = new VueRouter({
    mode: "history",
    routes,
})

export default router;