<template>
  <div id="homepage" class="mx-1440 pt-0">
  <HomepageHero />
  <HomepageServices />
  <HomepageProducts />
  <HomepageWhoweare />
  </div>
</template>

<script>
import HomepageHero from "@/components/HomepageComponents/HomepageHero.vue"
import HomepageServices from "@/components/HomepageComponents/HomepageServices.vue"
import HomepageProducts from "@/components/HomepageComponents/HomepageProducts.vue"
import HomepageWhoweare from "@/components/HomepageComponents/HomepageWhoweare.vue"
export default {
  name: 'HomePage',
  components: {
    HomepageHero,
    HomepageServices,
    HomepageProducts,
    HomepageWhoweare
  },mounted () {
  window.scrollTo(0, 0)
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
