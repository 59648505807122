<template>
  <div id="app">
    <HeaderComponent />
    <router-view style="padding-top:4.75rem;"/>
    <FooterComponent/>
  </div>
</template>

<script>
import HeaderComponent from "./components/HeaderComponent.vue"
import FooterComponent from "./components/FooterComponent.vue"
export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent
  }
};
</script>

<style>
@import "./assets/css/reset.css";
@import "./assets/css/all.scss";
*{
  font-family: 'Open Sans';
}

.header{

}
</style>
